import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import { jubileusze as content } from '../../content/kariera'
import { useLangContext } from '../../context/lang.context'
import { s } from '../../style'
import BlogTile from '../../sections/blog/BlogTile'
import Grid from '../../components/Grid'
import Button from '../../components/Button'

const buttonBack = {
  text: {
    pl: 'Więcej publikacji',
    en: 'Więcej publikacji',
  },
  link: '/blog/',
}

const Jubileusze = () => {
  const { lang } = useLangContext()

  const results = useStaticQuery(graphql`
    {
      blogs: allContentfulBlog(
        limit: 3
        filter: {
          blog_categories: {
            elemMatch: { blog_category: { eq: "Jubileusze" } }
          }
          blog_slug: { ne: null }
          title: { ne: null }
        }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          blog_slug
          node_locale
          title
          description
          date
          image {
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData(
              width: 640
              quality: 100
              formats: [JPG]
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `)

  const blogs = results.blogs.nodes

  return (
    <Section mobile top>
      <Headline
        h={2}
        title={content.title[lang]}
        desc={content.desc[lang]}
        large
      />
      <Grid container ccss={sContainer}>
        {blogs.map((blog, id) => (
          <BlogTile
            key={id}
            data={blog}
            // extraCss={[
            //   id >= 2 && { [s.sm_down]: { display: 'none' } },
            //   id >= 1 && { [s.xs]: { display: 'none' } },
            // ]}
          />
        ))}
      </Grid>
      <Grid container css={{ [s.sm]: { margin: '0 auto' } }}>
        <Button glow noMargin link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </Grid>
    </Section>
  )
}

const sContainer = {
  margin: '3rem 0 2rem',
  [s.xs]: {
    margin: '2rem 0 0',
  },
}

export default Jubileusze
