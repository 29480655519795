export const seo = {
  url: 'kariera',
  title: {
    pl: 'Kariera w firmie transportowej, która stawia na sukces',
    en: 'Career in a transport company that focuses on success',
  },
  desc: {
    pl: 'Dołącz do czołówki firm z branży TSL w Europie. Rozwijaj się w obszarze transportu, spedycji i logistyki. Sprawdź, jak pracuje się w Omidzie!',
    en: 'Join the leading companies in the TSL industry in Europe. Develop yourself in the area of ​​transport, forwarding and logistics. Check how it is working in Omida!',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Kariera',
    en: 'Career',
  },
  desc: {
    pl: 'Dołącz do czołówki firm z branży TSL w Europie. Rozwijaj się w obszarze transportu, spedycji i logistyki. Sprawdź, jak pracuje się w Omidzie!',
    en: 'Join the leading companies in the TSL industry in Europe. Develop yourself in the area of ​​transport, forwarding and logistics. Check how it is working in Omida!',
  },
  button: {
    text: {
      pl: 'Sprawdź możliwości',
      en: 'Check opportunities',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Kariera w firmie transportowej, która stawia na sukces',
        en: 'Start your career in a transport company that focuses on success',
      },
      texts: [
        {
          pl: '<span><strong>Dynamiczny rozwój</strong> oraz struktura OMIDA Group pozwala <strong>szybko podejmować kluczowe decyzje</strong>. Komplet certyfikatów ISO zwiększają możliwości sprzedażowe naszych spedytorów a renoma marki Omida jest kluczem do <strong>większych możliwości</strong>.</span>',
          en: '<span><strong>Dynamic development</strong> and the structure of OMIDA Group allows <strong>to make key decisions quickly</strong>. A set of ISO certificates increase the sales possibilities of our forwarders and the reputation of the Omida brand is the key to <strong>greater possibilities</strong>.</span>',
        },
        {
          pl: '<span>Nieustannie chcemy również rozwijać obszary działania Omida VLS, a tym samym otwierać kolejne <strong>biura operacyjno-handlowe</strong> na terenie naszego kraju i w Europie. Będąc w czołówce firm z branży TSL w Polsce, kładziemy nacisk na aspekt kluczowy w realizacji <strong>strategii dynamicznego rozwoju</strong>, czyli zwiększenie ilości klientów, transportów i <strong>inwestycja w ludzi</strong>, którzy są kluczowym elementem marki Omida.</span>',
          en: '<span>We also constantly want to develop the areas of operation of Omida VLS, and thus open new <strong>operational and commercial offices</strong> in our country and in Europe. Being at the forefront of TSL companies in Poland, we place emphasis on the key aspect in the implementation of <strong>dynamic development strategy</strong>, i.e. increasing the number of customers, transports and <strong>investment in people</strong>, who are a key element by Omida.</span>',
        },
      ],
    },
  ],
  features: [
    {
      icon: 'career1',
      subtitle: {
        pl: 'Rozwój',
        en: 'Growth',
      },
      title: {
        pl: '100%',
        en: '100%',
      },
      desc: {
        pl: 'Proponujemy swoim pracownikom unikatową ścieżkę kariery w TSL nastawioną na osiągnięcie sukcesu. To od Ciebie zależy jak szybko osiągniesz sukces',
        en: `We offer our employees a unique career path in TSL focused on achieving success. It's up to you how quickly you achieve success`,
      },
    },
    {
      icon: 'career2',
      subtitle: {
        pl: 'System',
        en: 'Bonus ',
      },
      title: {
        pl: 'Premiowy',
        en: 'System',
      },
      desc: {
        pl: 'Jesteś zmotywowany? Przygotowaliśmy dla Ciebie unikalny system premiowy!',
        en: 'Motivated already? We have prepared a unique bonus system for you!',
      },
    },
    {
      icon: 'career3',
      subtitle: {
        pl: 'Pełen ',
        en: 'Full',
      },
      title: {
        pl: 'Fokus',
        en: 'Focus',
      },
      desc: {
        pl: 'U nas zajmujesz się tylko tym, w czym jesteś najlepszy. Nasi spedytorzy mają do dyspozycji wewnętrzny dział prawny, windykacji, marketingu oraz HR',
        en: 'You only have to do what you are best at. Our forwarders have at their disposal internal legal, debt collection, marketing and HR departments',
      },
    },
    {
      icon: 'career4',
      subtitle: {
        pl: 'Wybór',
        en: 'Any type of',
      },
      title: {
        pl: 'Umowy',
        en: 'Contract',
      },
      desc: {
        pl: 'U nas to Ty decydujesz czy współpracujemy B2B czy na umowę o pracę',
        en: 'It is up to you to decide whether we cooperate on a B2B or an employment contract',
      },
    },
    {
      icon: 'career5',
      subtitle: {
        pl: 'Wsparcie',
        en: 'Support for',
      },
      title: {
        pl: 'Pasji',
        en: 'Hobbies',
      },
      desc: {
        pl: 'Wiemy, jak ważne jest dla Ciebie Twoje hobby. Od lat wspieramy naszych pracowników w realizacji ich marzeń i celów. Dołącz do nas i bądź częścią drużyny',
        en: 'We know how important your hobby is to you. For years, we have been supporting our employees in making their dreams and goals come true. Join us and be part of the team',
      },
    },
    {
      icon: 'career6',
      subtitle: {
        pl: 'Świetlna',
        en: 'Great',
      },
      title: {
        pl: 'Lokalizacja',
        en: 'Location',
      },
      desc: {
        pl: 'Posiadamy biura w największych centrach biznesowych, w świetnie skomunikowanych dzielnicach miast. Dołącz do jednego z naszych oddziałów!',
        en: 'We have offices in the largest business centers, in well-connected city districts. Join one of our branches now!',
      },
    },
  ],
}

export const video = {
  title: {
    pl: 'Mierz wysoko. Dołącz do silnej marki.',
    en: 'Aim high. Join the strong one',
  },
  url: 'https://www.youtube.com/watch?v=cC89OhOTSZk',
  more: [
    'https://www.youtube.com/watch?v=hnPotrKxOVs',
    'https://www.youtube.com/watch?v=w_7GdMYcgLg',
    'https://www.youtube.com/watch?v=HMkamapqpaY',
    'https://www.youtube.com/watch?v=otea6QesV1Y',
  ],
}

export const jobs = {
  title: {
    pl: 'Wybierz swoje miasto. Aplikuj teraz!',
    en: 'Choose your city. Apply now!',
  },
  desc: {
    pl: 'Posiadamy oddziały w ponad 30 miejscowościach w Polsce. Chcesz dołączyć do nas ze swoim zespołem? Skontaktuj się z nami hr@omida-vls.com',
    en: 'We have branches in over 30 locations in Poland. Would you like to join us with your team? Contact us at hr@omida-vls.com',
  },
}

export const jubileusze = {
  title: {
    pl: 'Tworzymy zespół, budujemy relacje',
    en: 'Join #OmidaTeam 💪',
  },
  desc: {
    pl: 'Pracujemy wspólnie, ciesząc się chwilami podczas integracji i wspólnych inicjatyw, które budują nasze relacje.',
    en: 'Obchodzimy Jubileusze pracowników w Omida VLS! Dziękujemy, że jesteście z nami. 🌟 Together We Are Strong',
  },
}
